









































import { Component, Prop, Vue, VModel } from "vue-property-decorator";

@Component({})
export default class StepperPage extends Vue {
  @Prop({ type: Boolean, default: false })
  first!: boolean; // determines whether 'previous' button is shown

  @Prop({ type: Boolean, default: false })
  last!: boolean; // determines whether 'next' button is shown

  @Prop({ type: Number, default: 1 })
  page!: number; // the current page

  @VModel({ type: Number, default: 1 })
  stepper!: number;

  @Prop({ type: Boolean, default: true })
  enableNext!: boolean;

  onClickPrevious() {
    this.stepper = this.stepper - 1;
    this.$emit("stepper-previous");
  }

  onClickNext() {
    this.stepper = this.stepper + 1;
    this.$emit("stepper-next");
  }
}
