













































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ImageUploadDraggable from "@/components/ImageUploadDraggable.vue";
import { readServerImages } from "@/store/admin/getters";
import {
  commitSetArchiveServerImage,
  commitSetUserImages,
} from "@/store/admin/mutations";
import { ISample } from "@/interfaces";

@Component({
  components: {
    ImageUploadDraggable,
  },
})
export default class ImageEditPage extends Vue {
  @Prop({ type: Object, default: {} })
  sampleData!: ISample;

  userPhotos: File[] = [];

  get serverImagesExist() {
    return this.filteredServerImages.length !== 0;
  }

  get filteredServerImages() {
    return readServerImages(this.$store).filter((x) => x.data.archived === false);
  }

  get userPhotosExist() {
    return this.userPhotos.length !== 0;
  }

  photoUrl(file) {
    return URL.createObjectURL(file);
  }

  removePhoto(file) {
    this.userPhotos = this.userPhotos.filter((x) => x !== file);
  }

  removeServerImage(photo) {
    commitSetArchiveServerImage(this.$store, { id: photo.data.id, archived: true });
  }

  @Watch("userPhotos")
  userPhotosChanged() {
    commitSetUserImages(this.$store, this.userPhotos);
  }
}
