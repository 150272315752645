

































































































































import { Component, Vue } from "vue-property-decorator";
import BarcodeScanner from "@/components/BarcodeScanner.vue";
import NavBar from "@/components/NavBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import StepperPage from "@/components/StepperPage.vue";
import ImageEditPage from "@/components/ImageEditPage.vue";
import {
  dispatchPostImage,
  dispatchGetImagesForSample,
  dispatchUpdateSampleImage,
  dispatchGetSampleForTestId,
} from "@/store/admin/actions";

import { ISample, ISampleImageEdit } from "@/interfaces";
import { readServerImages, readUserImages } from "@/store/admin/getters";
import { commitSetServerImages, commitSetUserImages } from "@/store/admin/mutations";

const STATUS = {
  READY: 1,
  LOADING: 2,
  SUCCESS: 3,
  FAILURE: 4,
};

@Component({
  components: {
    BarcodeScanner,
    NavBar,
    NavDrawer,
    StepperPage,
    ImageEditPage,
  },
})
export default class ImageUpload extends Vue {
  testIdInput = "";
  stepper = 1;
  resetScanner = false;
  sampleData: ISample | null = null;
  testIdStatus: number = STATUS.READY;
  uploadStatus: number = STATUS.READY;

  get testIdValid() {
    return this.testIdStatus === STATUS.SUCCESS && this.sampleData;
  }

  get testIdLoading() {
    return this.testIdStatus === STATUS.LOADING;
  }

  get uploadReady() {
    return this.uploadStatus === STATUS.READY;
  }

  get uploadSuccess() {
    return this.uploadStatus === STATUS.SUCCESS;
  }

  get uploadFailed() {
    return this.uploadStatus === STATUS.FAILURE;
  }

  get uploadLoading() {
    return this.uploadStatus === STATUS.LOADING;
  }

  get isIdValid() {
    return this.testIdInput !== "";
  }

  get photosChanged() {
    return this.userImages.length > 0 || this.archivedImages.length > 0;
  }

  get archivedImages() {
    const archivedImages = readServerImages(this.$store).filter(
      (x) => x.data.archived === true,
    );
    return archivedImages;
  }

  get userImages() {
    return readUserImages(this.$store);
  }

  onScanComplete(value) {
    this.testIdInput = value;
  }

  photoUrl(file) {
    return URL.createObjectURL(file);
  }

  reset() {
    this.stepper = 1;
    this.testIdInput = "";
    this.uploadStatus = STATUS.READY;
    this.resetScanner = !this.resetScanner;
    this.sampleData = null;
    commitSetUserImages(this.$store, []);
    commitSetServerImages(this.$store, []);
  }

  async uploadUserImage(file: File) {
    let r = await dispatchPostImage(this.$store, {
      test_id: this.testIdInput,
      file: file,
    });
    if (!r) {
      this.uploadStatus = STATUS.FAILURE;
    }
  }

  async updateServerImage(image: ISampleImageEdit) {
    let r = await dispatchUpdateSampleImage(this.$store, image.data);
    if (!r) {
      this.uploadStatus = STATUS.FAILURE;
    }
  }

  async upload() {
    this.uploadStatus = STATUS.LOADING;

    if (this.userImages.length > 0) {
      for (const file of this.userImages) {
        await this.uploadUserImage(file);
      }
    }
    if (this.archivedImages.length > 0) {
      for (const image of this.archivedImages) {
        await this.updateServerImage(image);
      }
    }

    if (this.uploadStatus !== STATUS.FAILURE) {
      this.uploadStatus = STATUS.SUCCESS;
    }
  }

  async checkTestId() {
    this.testIdStatus = STATUS.LOADING;
    let response = await dispatchGetSampleForTestId(this.$store, this.testIdInput);
    this.sampleData = response ? response.data : null;
    if (this.sampleData && this.sampleData.sample_number) {
      await dispatchGetImagesForSample(this.$store, {
        sample_id: this.sampleData.sample_number,
      });
      this.testIdStatus = STATUS.SUCCESS;
    } else {
      this.testIdStatus = STATUS.FAILURE;
    }
  }
}
