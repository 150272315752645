































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Quagga from "@ericblade/quagga2";
import { IVideoDevice } from "@/interfaces";

@Component
export default class BarcodeScanner extends Vue {
  code: string | null = null;
  codes: string[] = [];
  showOverlay = false;
  devices: IVideoDevice[] = [];
  selectedDevice: IVideoDevice = { id: "", text: "" };
  isLoading = true;
  codeStreak = 3; // number of sequential codes we need to read before finalizing result
  changeCameraDialog = false; // a dialog box to change camera view

  @Prop({ type: Boolean, default: false })
  reset!: boolean; // reset everything

  $refs!: {
    quagga: HTMLVideoElement;
  };

  get progress() {
    let progress = (this.codes.length / this.codeStreak) * 100;
    return progress;
  }

  async mounted() {
    if (!this.isMediaStreamSupported()) {
      console.error("Media Stream Not Supported");
    } else {
      await this.getMediaDevices();
      this.initializeQuagga();
    }
  }

  async beforeDestroy() {
    Quagga.stop();
  }

  isMediaStreamSupported() {
    return (
      navigator &&
      navigator.mediaDevices &&
      "enumerateDevices" in navigator.mediaDevices
    );
  }

  async getMediaDevices() {
    await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    let allDevices = await navigator.mediaDevices.enumerateDevices();
    if (allDevices.length > 0) {
      allDevices.forEach((device) => {
        if (device.kind === "videoinput") {
          this.devices.push({ id: device.deviceId, text: device.label });
        }
      });
      this.selectedDevice = this.devices[0];
      this.isLoading = false;
    } else {
      console.error("No camera devices found");
    }
  }

  async handleChangeCamera() {
    this.isLoading = true;
    this.changeCameraDialog = false;
    this.clearValues();
    this.initializeQuagga();
  }

  clearValues() {
    this.codes = [];
    this.code = null;
    this.showOverlay = false;
  }

  setResult(value) {
    this.code = value;
    this.$emit("scan-complete", value);
  }

  async initializeQuagga() {
    await Quagga.stop();

    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: this.$refs.quagga,
          constraints: {
            deviceId: this.selectedDevice.id,
            facingMode: "environment",
          },
        },
        decoder: {
          readers: ["code_39_reader", "code_128_reader"],
        },
        locate: true,
        locator: {
          halfSample: true,
        },
      },
      function (err) {
        if (err) {
          console.error("Quagga error", err);
          return;
        }
        Quagga.start();
      },
    );

    Quagga.onDetected((result) => {
      this.checkResult(result);
    });

    this.isLoading = false;
  }

  checkResult(result) {
    const resultCode = result.codeResult.code;
    this.codes.push(resultCode);

    if (this.codes.length >= this.codeStreak) {
      let is_same = this.areCodesSame();
      if (!is_same) {
        this.codes.shift();
        return;
      } else {
        this.pauseQuagga();
        this.setResult(resultCode);
      }
    }
  }

  async pauseQuagga() {
    this.showOverlay = true;
    await Quagga.stop();
  }

  areCodesSame() {
    return this.codes.every((val) => val === this.codes[0]);
  }

  async retry() {
    this.clearValues();
    this.initializeQuagga();
  }

  @Watch("reset")
  async reset_scanner() {
    this.retry();
  }
}
