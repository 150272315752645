
























import { Component, Vue, Prop, VModel } from "vue-property-decorator";

@Component
export default class ImageUploadDraggable extends Vue {
  @Prop({ type: Boolean, default: true })
  multiple!: boolean; // allow multiple pictures or not

  @VModel({ type: Array, default: [] })
  photoFiles!: File[];

  $refs!: {
    photoInput: HTMLInputElement;
  };

  get disableInput() {
    return this.photoFiles.length > 0 && !this.multiple;
  }

  saveFiles(fileList: FileList) {
    if (!fileList.length) return;

    [...fileList].forEach((file) => {
      this.photoFiles.push(file);
    });

    // clear the form value - we now have this info stored in our photoFiles array
    this.$refs.photoInput.value = "";
  }
}
